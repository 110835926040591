<template>
  <div class="step1">
    <div class="tag-wrap">
      <div
        class="tag"
        v-for="(item, index) in list"
        :key="index"
        :class="{ active: index === tagIndex }"
        @click="tagIndex = index"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="book-wrap" v-if="list[tagIndex]">
      <div
        class="book-item"
        v-for="item in list[tagIndex].list"
        :key="item.id"
        @click="toNextStep(item.play_id)"
      >
        <div class="book">
          <div class="weekday">{{ item.date_yyyy_mm_dd | weekday }}</div>
          <div class="time">{{ item.time }}</div>
          <div class="team">{{ item.zteam }}—{{ item.kteam }}</div>
        </div>
        <img
          class="book-shadow"
          src="@/assets/display/step1/book_shadow.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { showAll } from "@/api/home.js";
export default {
  filters: {
    weekday(val) {
      var date = new Date(val);
      var dayIndex = date.getDay(); //获取存储当前日期
      var weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      return weekday[dayIndex];
    }
  },
  data() {
    return {
      tagIndex: 0,
      list: []
    };
  },
  mounted() {
    this.showAll();
  },
  methods: {
    toNextStep(id) {
      this.$router.push({
        path: "/display/step2",
        query: {
          id
        }
      });
    },
    showAll() {
      showAll().then(res => {
        let list = [];
        res.data.forEach(item => {
          let league = item.league;
          let leagueItem = list.find(listItem => listItem.name === league);
          if (!leagueItem) {
            list.push({
              name: league,
              list: [item]
            });
          } else {
            leagueItem.list.push(item);
          }
        });
        this.list = list;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.step1 {
  background-image: url("../../assets/display/step1/bg.png");
  background-repeat: no-repeat;
  min-height: 1080px;
  background-position: center top;
  position: relative;
  background-color: #000;
  min-height: 100vh;
  .tag-wrap {
    position: absolute;
    left: 50%;
    top: 230px;
    transform: translateX(-800px);
    height: 800px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .tag {
      cursor: pointer;
      font-family: "楷体", "楷体_GB2312";
      font-size: 40px;
      font-weight: bold;
      color: #492a21;
      writing-mode: vertical-lr;
      letter-spacing: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      box-sizing: border-box;
      width: 98px;
      height: 183px;
      background-image: url("../../assets/display/step1/tag.png");
      background-position-x: 2px;
      position: relative;
      &.active {
        background-image: url("../../assets/display/step1/tag_active.png"),
          url("../../assets/display/step1/tag.png");
        text-shadow: 0 0 2px #f0dfbe;
      }
    }
  }
  .book-wrap {
    width: 1100px;
    position: absolute;
    left: 50%;
    top: 275px;
    transform: translateX(-50%);
    padding-left: 70px;
    .book-item {
      position: relative;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 30px;
      cursor: pointer;
      font-size: 20px;
      .book {
        position: relative;
        z-index: 100;
        width: 200px;
        height: 280px;
        background-image: url("../../assets/display/step1/book.png");
        background-size: cover;
        &:hover {
          filter: brightness(1.5);
        }

        .weekday {
          position: absolute;
          left: 52px;
          top: 70px;
        }
        .time {
          position: absolute;
          left: 68px;
          top: 90px;
        }
        .team {
          position: absolute;
          writing-mode: vertical-lr;
          position: absolute;
          right: 36px;
          font-size: 22px;
          top: 12px;
          font-weight: bold;
          // 文字间距
          letter-spacing: 2px;
        }
      }
      .book-shadow {
        position: absolute;
        left: -33px;
        bottom: -6px;
      }
    }
  }
}
</style>
